import React, { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase_setup/firebase";
import Header from "./Header";
import EmailForm from "./EmailForm";
import SubmittedMessage from "./SubmittedMessage";
import "../styles/components/LandingPage.css";

/**
 * LandingPage Component
 *
 * This component serves as the main landing page of the application.
 * It contains the header, email form, and the message displayed upon successful email submission.
 */
const LandingPage = () => {
  const [email, setEmail] = useState(""); // Stores the user's email input
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false); // Tracks if the email has been submitted
  const [shakeAnimation, setShakeAnimation] = useState(false); // Controls the shake animation for invalid input
  const [inputError, setInputError] = useState(false); // Tracks if there is an error with the email input

  useEffect(() => {
    if (document.referrer) {
      logEvent(analytics, "referrer", {
        referrer_url: document.referrer, // Logs the referrer URL
      });
    }
    logEvent(analytics, "Landing Page", {
      description: "Nannie home page visited", // Logs that the landing page was visited
    });
  }, []);

  // Reload the page
  const handleReload = () => {
    window.location.reload(); // Reloads the current webpage
  };

  return (
    <div className="flex flex-col w-full items-center justify-center h-screen relative">
      <Header handleReload={handleReload} /> {/* Header component */}
      <div>
        {!isEmailSubmitted ? (
          <EmailForm
            email={email}
            setEmail={setEmail}
            setIsEmailSubmitted={setIsEmailSubmitted}
            shakeAnimation={shakeAnimation}
            setShakeAnimation={setShakeAnimation}
            inputError={inputError}
            setInputError={setInputError}
          />
        ) : (
          <SubmittedMessage /> // Display message after email is submitted
        )}
      </div>
      <div className="absolute mb-4 bottom-0 w-full text-center text-black text-xs fade-in-delayed contact-info">
        contact@nannie.ai
      </div>
    </div>
  );
};

export default LandingPage;
