// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getFirestore } from "@firebase/firestore";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAFXrt0Zg3uf1C3lh3EpWNYFzlftJXnZ6w",
  authDomain: "nannieai-website-stealth.firebaseapp.com",
  projectId: "nannieai-website-stealth",
  storageBucket: "nannieai-website-stealth.appspot.com",
  messagingSenderId: "405737646974",
  appId: "1:405737646974:web:6e36ec98360b6a1cb2212f",
  measurementId: "G-KSD4MQR860",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(firebaseApp);
// Initialize Fire store
const db = getFirestore(firebaseApp);

export { db, analytics };
