import React, { useEffect } from "react";
import "./App.css";
import LandingPage from "./components/LandingPage";
import "./styles/base.css";  // Importing global styles
import "./styles/animations.css";  // Importing animations
import "./styles/utilities.css";  // Importing utility classes

function App() {
  useEffect(() => {
    // Hotjar Tracking Code
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5096415,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }, []);

  return (
    <>
      <LandingPage />
    </>
  );
}

export default App;
