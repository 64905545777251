import React from "react";
import tick from "../assets/images/tick-green.png";

/**
 * SubmittedMessage Component
 *
 * This component displays a message and a tick icon upon successful email submission.
 */
const SubmittedMessage = () => {
  return (
    <div className="flex items-center flex-col justify-center mt-10">
      <p className="text-lg font-normal mb-2">You are on the list!</p>
      <img className="w-23 h-20" src={tick} alt="Tick logo" />
    </div>
  );
};

export default SubmittedMessage;
