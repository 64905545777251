import React, { useState, useEffect } from "react";
import NannieIconBottom from "../assets/svg/NannieIcon_bottom.svg";
import NannieIcon from "../assets/svg/NannieIcon.svg";
import "../styles/components/Header.css";

/**
 * Header Component
 *
 * This component displays the header section of the landing page, including
 * the "Meet Nannie" text and the Nannie icon.
 *
 * @param {function} handleReload - Function to reload the page.
 */
const Header = ({ handleReload }) => {
  // State variables for animation classes
  const [addClassToNannie, setAddClassToNannie] = useState(false); // Controls animation for "Nannie" text
  const [addClassToImgBox, setAddClassToImgBox] = useState(false); // Controls animation for icon box

  // Add animation classes after specific time intervals
  useEffect(() => {
    const timers = [
      setTimeout(() => setAddClassToNannie(true), 800), // Add class to "Nannie" after 800ms
      setTimeout(() => setAddClassToImgBox(true), 1300), // Add class to icon box after 1300ms
    ];

    return () => timers.forEach((timer) => clearTimeout(timer)); // Clear timers on cleanup
  }, []);

  return (
    <>
      <p
        className={`font-museo-moderno font-semibold mb-8 text-name -mt-10 ${
          addClassToNannie ? "new-class-name" : ""
        }`}
        onClick={handleReload}
        style={{ cursor: "pointer" }}
      >
        Nannie
      </p>
      <div
        className={`imgBox ${addClassToImgBox ? "new-class-imgBox" : ""}`}
        onClick={handleReload}
        style={{ cursor: "pointer" }}
      >
        <img
          className="NannieIcon drop-shadow-2xl"
          src={NannieIcon}
          alt="Nannie Icon"
        />
        <img
          className="NannieIconBottom drop-shadow-2xl"
          src={NannieIconBottom}
          alt="Nannie Icon Bottom"
        />
      </div>
    </>
  );
};

export default Header;
