import React, { useState, useEffect } from "react";
import { addDoc, collection } from "@firebase/firestore";
import { db, analytics } from "../firebase_setup/firebase";
import { logEvent } from "firebase/analytics";
import "../styles/components/EmailForm.css";

/**
 * EmailForm Component
 *
 * This component displays the email input form and handles the email submission.
 *
 * @param {string} email - The email state variable.
 * @param {function} setEmail - Function to set the email state.
 * @param {function} setIsEmailSubmitted - Function to set the email submitted state.
 * @param {boolean} shakeAnimation - State variable to handle shake animation.
 * @param {function} setShakeAnimation - Function to set shake animation state.
 * @param {boolean} inputError - State variable to handle input error state.
 * @param {function} setInputError - Function to set input error state.
 */
const EmailForm = ({
  email,
  setEmail,
  setIsEmailSubmitted,
  shakeAnimation,
  setShakeAnimation,
  inputError,
  setInputError,
}) => {
  const [addClassToButton, setAddClassToButton] = useState(false); // Controls animation for the submit button
  const [errorMessage, setErrorMessage] = useState(""); // Controls the display of error message

  // Add animation class to the button after a specific time interval
  useEffect(() => {
    const timer = setTimeout(() => setAddClassToButton(true), 2200); // Add class to button after 2200ms

    return () => clearTimeout(timer); // Clear timer on cleanup
  }, []);

  // Validate the email format
  const validateEmail = () => {
    let isValid = true;
    if (!email.trim()) {
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      isValid = false;
    }

    return isValid; // Returns true if email is valid, false otherwise
  };

  // Handle email submission
  const handleEmailSubmit = async (event) => {
    event.preventDefault(); // Prevents default form submission behavior

    if (!validateEmail()) {
      setShakeAnimation(true); // Triggers shake animation for invalid input
      setInputError(true); // Sets input error state
      setErrorMessage("Please enter a valid email"); // Sets error message
      setTimeout(() => {
        setShakeAnimation(false);
        setInputError(false);
        setErrorMessage(""); // Clears error message after animation completes
      }, 2000);
      return;
    }

    const now = new Date();
    const date = now.toISOString().split("T")[0]; // Gets the current date in YYYY-MM-DD format
    const time = now.toTimeString().split(" ")[0]; // Gets the current time in HH:MM:SS format

    try {
      await addDoc(collection(db, "users"), {
        email: email, // Stores the user's email in the database
        date: date,
        time: time,
      });
      setEmail(""); // Clears the email input field
      setIsEmailSubmitted(true); // Sets the email submitted state to true
      logEvent(analytics, "Email submission", {
        description: "email submitted and joined waiting list", // Logs the email submission event
      });
    } catch (error) {
      console.error("Error adding document: ", error); // Logs any errors that occur
    }
  };

  // Handle keydown event for Enter key
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEmailSubmit(event);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <input
        type="text"
        id="email"
        className={`textfield bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 
     dark:placeholder-gray-900 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 w-56 mt-10 placeholder: text-center h-10 ${
       addClassToButton ? "new-class-textfield" : ""
     } ${shakeAnimation ? "shake" : ""} ${inputError ? "input-error" : ""}`}
        placeholder="Enter your email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)} // Updates email state on input change
        onKeyDown={handleKeyDown} // Handles keydown event for Enter key
        style={{
          padding: "12px 16px",
          borderRadius: "25px",
          transition: "all 0.3s ease",
          border: "1px solid #ddd", // Light border for a cleaner look
        }}
      />
      {errorMessage && (
        <p className="text-red-500 mt-2">{errorMessage}</p> // Error message displayed when email is invalid
      )}
      <button
        className={`accessbtn text-white font-medium py-2 px-8 rounded-full mt-4 ${
          addClassToButton ? "new-class-button" : ""
        }`}
        onClick={handleEmailSubmit} // Submits the email form
        style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          transition: "all 0.3s ease",
          background: "linear-gradient(145deg, #000000, #333333)",
          borderRadius: "25px",
          border: "1px solid #000",
          textShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
        }}
      >
        Join Waitlist
      </button>
    </div>
  );
};

export default EmailForm;
